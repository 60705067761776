var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-12 pb-12"},[(_vm.ready && !_vm.details)?_c('v-card',{staticClass:"transparent pb-12 px-12",attrs:{"flat":""}},[_c('v-card-title',[_c('strong',[_vm._v("DGtek partners list")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-12 mb-5",attrs:{"text":""},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Refresh ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.resellers,"search":_vm.search,"fixed-header":"","footer-props":{
        showFirstLastPage: true,
        itemsPerPage: 10,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        firstIcon: 'mdi-skip-previous',
        lastIcon: 'mdi-skip-next',
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right'
      }},on:{"click:row":_vm.showDetails},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.getPartnerCredentials(item)}}},[_vm._v(" mdi-open-in-new ")])]}}],null,false,73051331)}),_c('v-text-field',{staticClass:"bottom-search-input",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('span',{staticClass:"bottom-info-span"},[_c('small',[_vm._v(" Total number of partners: "+_vm._s(_vm.resellers.length)+" ")])])],1):_vm._e(),(_vm.details)?_c('ResellerPages',{attrs:{"opened":_vm.details},on:{"update:opened":function($event){_vm.details=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }